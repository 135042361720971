<template>
  <div class="home">
    <div class="homeCard isMe">
      <div class="isMoneyIntroduce">
        <div class="align-center row-between row introduceCard">
          <div class="headImg"/>
          <div class="row-between homeText flex1 align-center">
            <div>
              <div class="userName">
                {{users.nickname.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')}}
              </div>
              <div class="residualDegree">
                <span v-if="level == 0">剩余提问次数：{{residualDegree}} 次</span>
                <span v-else>会员有效期：{{expiretime || '--'}}</span>
              </div>
            </div>
            <!--     level等级0普通用户1皇冠用户     -->
            <span
                class="iconfont icon-vipMember vipMember"
                :class="level == 0 ? '' : 'vip'">
          </span>
          </div>
        </div>
      </div>
    </div>
    <div class="homeCard pay">
      <div class="payMain">
        <b class="payText">购买会员</b>
        <div class="payTitle">成为vip会员 即可无限次对话</div>
        <div class="vipPayMain">
          <div class="row-between vipMoney">
            <div
                v-for="item in vipMoney"
                :key="item.id"
                class="vipMoneyLi"
                :class="vipMoneySelected == item.id ? 'selected' : ''"
                @click="changeVipMoney(item.id)">
              <div class="payMonth">
                {{item.dayText}}
              </div>
              <b class="vipMoneyFont">
                <span class="vipSmall">¥</span>{{item.currentPrice}}
              </b>
              <div class="vipMoneyFontOld">
                原价{{item.originalPrice}}元
              </div>
              <div class="singe align-center"> </div>
              <div class="singeDui"></div>
            </div>
          </div>
          <div class="vipMoneyType row-center align-center">
            <div
                v-for="item in vipMoneyType"
                :key="item.id"
                class="vipMoneyTypeLi row-center align-center"
                :class="[item.type == vipMoneyTypeSelcted ? 'selected' : '', item.id]"
                @click="changeVipMoneyType(item.type)">
              <span class="iconfont" :class="item.icon" ></span>
              <span>{{item.name}}</span>
            </div>
          </div>
          <div class="row-center payApply">
            <van-button
                @click="loadingMemberOrder"
                icon="icon-svip">
              立即开通
            </van-button>
          </div>
        </div>
        <div class="isMoney">
          <div class="isMoneyTitle">会员权益</div>
          <div class="homeCardMain">
            <div class="row flex-wrap vipList ">
              <div v-for="item in vipList" :key="item.id" class="vipListLi row row-between">
                <div
                  class="vipListImg"
                  :class="item.img"
                ></div>
                <div class="flex1">
                  <div class="title">{{item.title}}</div>
                  <div class="main">{{item.main}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="payDiv"></div>
    <div v-if="codeImg">
      <el-dialog
        v-model="codeImg"
        title="微信扫码支付"
        width="400px"
        :before-close="handleClose"
      >
        <div class="row-center align-center">
          <div id="qrcodeImg" ref="qrcode"></div>
        </div>
        <template #footer>
          <!--      <span class="dialog-footer">-->
          <!--        <el-button @click="">确定</el-button>-->
          <!--        <el-button type="primary" @click="codeImg = false">-->
          <!--          关闭-->
          <!--        </el-button>-->
          <!--      </span>-->
        </template>
      </el-dialog>
    </div>
    <!-- 此处已经对接微信内支付，不需要了
    <van-popup v-model:show="weixinType" round :style="{ padding: '64px' }" >
      点击右上角，打开默认浏览器，完成支付
    </van-popup>
    -->
    <van-popup v-model:show="userApply" round :style="{ padding: '64px' }" :before-close="userApplyFun">
      <div class="row-center userApplyText">请在打开的页面完成支付</div>
      <div class="row-center">
        <van-button type="primary" @click="userApplyFun">我已支付完成</van-button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ref, reactive, toRefs, onMounted, computed, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import { queryOrderType, memberOrder, payOrderFinishQuery } from '@/api/home'
import QRCode from 'qrcodejs2'
import { showNotify } from 'vant'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router'
import exclusive from './images/exclusive.png'
import delay from './images/delay.png'
import infinite from './images/infinite.png'
import kf from './images/kf.png'
import wx from 'weixin-js-sdk'
import {_isMicroMessager} from '../../utils/common' // 待确定是否需要

export default {
  name: 'register',
  setup () {
    const store = useStore()
    const active = ref(1)
    const route = useRoute()
    const router = useRouter()
    const loopTime = 3000
    const isWeixin = _isMicroMessager()
    let invokeMax = 50
    let invokeCount = 0
    let qrCode = null
    let setTimeoutT = ''
    const state = reactive({
      level: computed(() => store.getters.roles),//用户角色
      users: store.getters.users,//用户名
      expiretime: computed(() => store.getters.expiretime),//会员到期时间
      residualDegree: computed(() => store.getters.residualDegree),//非会员剩余几次
      vipList: [
        {
          img: 'exclusive',
          id: 'vipList1',
          title: '独享接口',
          main: '一人独享一条接口畅快对话'
        },
        {
          img: 'delay',
          id: 'vipList2',
          title: '超低延迟',
          main: '超低延迟回复秒回'
        },
        {
          img: 'infinite',
          id: 'vipList3',
          title: '无限畅聊',
          main: '不受对话次数限制'
        },
        {
          img: 'kf',
          id: 'vipList4',
          title: '专属客服',
          main: '专属一对一客服服务'
        }
      ],//会员享受福利
      vipMoney: [
        {
          name: '永久会员',
          money: '180',
          oldMoney: '600',
          id: 'one'
        },
        {
          name: '1个月',
          money: '17.9',
          oldMoney: '30',
          id: 'two'
        },
        {
          name: '2个月',
          money: '39.9',
          oldMoney: '180',
          id: 'three'
        }
      ],//支付金额
      vipMoneySelected: 'one',
      vipMoneyType: isWeixin ? [
        {
          name: '微信支付',
          id: 'weixin',
          icon: 'icon-weixinzhifu1',
          type: '1'
        }
      ] : [
        {
          name: '微信支付',
          id: 'weixin',
          icon: 'icon-weixinzhifu1',
          type: '1'
        },
        {
          name: '支付宝支付',
          id: 'zhifubao',
          icon: 'icon-zhifubao',
          type: '2'
        }
      ],//支付状态
      vipMoneyTypeSelcted: '1',
      imgSrc: '',
      codeImg: false, //微信扫码支付
      orderId: '', //订单号
      pcCodeImgSet: '',
      weixinType: false,//浏览器类型
      userApply: false,//跳转确定支付状态的按钮
      time888: ''
    })
    const pcCodeImgSet = ''
    const { proxy } = getCurrentInstance()
    const methods = reactive({
      changeVipMoney (id) {
        state.vipMoneySelected = id
      },
      changeVipMoneyType (id) {
        state.vipMoneyTypeSelcted = id
      },
      // 获取订单类型 1个月还是多个月
      loadingQueryOrderType () {
        queryOrderType().then(res => {
          state.vipMoney = res.data
          state.vipMoneySelected = res?.data[0]?.id
        })
      },
      // 充值/order/memberOrder
      loadingMemberOrder () {
        let payMode = store.getters.isModel === true ? 1 : 2
        if(isWeixin){
          payMode = 3
        }
        memberOrder({
          orderTypeId: state.vipMoneySelected,
          payType: state.vipMoneyTypeSelcted,
          payMode: payMode
        }).then(res => {
          state.orderId = res.data.orderId // 订单id
          if (state.vipMoneyTypeSelcted == 2) {
            methods.loadingPayZhi(res)
            state.userApply = true
          } else {
            // 微信支付 isModel == true => h5 isModel == false => pc
            // isWeixin => 判断是否是微信浏览器
            if (store.getters.isModel) {
              if (isWeixin) {
                console.log(res.data.jsapiForMemberOrderMap)
                const jsapiParam = res.data.jsapiForMemberOrderMap
                function onBridgeReady() {
                  WeixinJSBridge.invoke('getBrandWCPayRequest', {
                        "appId": jsapiParam.appId,     //公众号ID，由商户传入
                        "timeStamp": jsapiParam.timeStamp,     //时间戳，自1970年以来的秒数
                        "nonceStr": jsapiParam.nonceStr,      //随机串
                        "package": jsapiParam.package,
                        "signType": jsapiParam.signType,     //微信签名方式：
                        "paySign": jsapiParam.paySign //微信签名
                      },
                      function(res) {
                        if (res.err_msg == "get_brand_wcpay_request:ok") {
                          // 使用以上方式判断前端返回,微信团队郑重提示：
                          //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                          methods.userApplyFun()
                        }
                      })
                }
                if (typeof WeixinJSBridge == "undefined") {
                  if (document.addEventListener) {
                    document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
                  } else if (document.attachEvent) {
                    document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
                    document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
                  }
                } else {
                  onBridgeReady()
                }

                state.userApply = true
                state.weixinType = true
              } else {
                window.location.href = res.data.payJsInfo // 微信h5跳转地址
                state.userApply = true
              }
            } else {
              // pc端生成二维码 进行微信支付
              state.codeImg = true
              setTimeout(() => {
                qrCode = new QRCode('qrcodeImg', {
                  width: 300,
                  height: 300,
                  text: res.data.payJsInfo// 二维码地址
                })
              }, 500)
              methods.loopOrderState()
            }
          }
        })
      },
      // 支付宝支付
      loadingPayZhi (res) {
        let div = document.querySelector('#payDiv')
        // 如果页面已经存在payDiv，则只要修改payDiv的innerHTML就可以了，否则提交的表单永远是第一个表单。
        if (!div) {
          div = document.createElement('div')
          div.id = 'payDiv'
          document.body.appendChild(div)
        }
        div.innerHTML = res.data.payJsInfo
        document.forms[0].submit()
      },
      cleanTimer () {
        clearInterval(setTimeoutT)
      },
      // 轮询当前订单状态
      loopOrderState: async () => {
        invokeCount++
        const res = await payOrderFinishQuery(state.orderId)
        if (res.code === 0 && res.data) {
          invokeCount = 0
          // proxy.$refs.qrCode.innerHTML = ''
          // qrCode.clear()
          methods.finishOrder()
        } else {
          if (invokeCount === invokeMax) {
            showNotify({ type: 'danger', message: '支付长时间未响应，请重新支付' })
            invokeCount = 0
            // qrCode.clear()
            // proxy.$refs.qrCode.innerHTML = ''
            state.codeImg = false
            state.userApply = false
            return
          }
          setTimeoutT = setTimeout(() => {
            methods.loopOrderState()
          }, loopTime)
        }
      },
      // 查询订单状态
      // loadingPayOrderFinishQuery () {
      //   payOrderFinishQuery(state.orderId).then(res => {
      //     return res.data
      //   })
      // },
      // 完成支付
      finishOrder () {
        state.codeImg = false
        state.userApply = false
        showNotify({ type: 'success', message: '支付成功' })
        methods.refreshGetInfo()
      },
      // 刷新用户信息
      refreshGetInfo () {
        state.time888 = new Date()
        store.dispatch('GetInfo').then(() => {})
      },
      // 判断路由变化http://192.168.3.8:2021/#/home?orderId=3066428391642103808&paytype=1
      routerChange () {
        if (route.query.orderId && route.query.paytype) {
          state.orderId = route.query.orderId
          methods.userApplyFun()
        }
      },
      // 关闭弹框
      handleClose () {
        invokeCount = 0
        methods.cleanTimer()
        state.codeImg = false
        methods.refreshGetInfo()
      },
      //用户主动点击我已支付按钮
      userApplyFun () {
        payOrderFinishQuery(state.orderId).then(res => {
          state.userApply = false
          if (res.data) {
            methods.finishOrder()
          } else {
            showNotify({ type: 'warning', message: '支付失败，若已完成支付请联系客服' })
          }
        })
      }
    })
    onMounted(() => {
      methods.loadingQueryOrderType()
      methods.routerChange()
      //监听页面状态
      document.addEventListener("visibilitychange", () => {
        if(document.hidden) {
          // 页面被挂起
          state.time888="页面被挂在"
        }
        else {
          //更新用户信息
          methods.refreshGetInfo()
          // 页面呼出
        }
      });
    })
    return {
      active,
      ...toRefs(state),
      ...toRefs(methods)
    }
  }
}
</script>
<style scoped lang="less">
@import "./common";
.homeScroll{
  overflow-y: auto;
}
</style>
